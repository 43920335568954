import React from 'react'
import { render, hydrate } from 'react-dom'
import './index.scss'
import App from './components/App'
// import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import store from './store/index'
import setInitial from './actions/index'
import { initAuth } from './thunks/auth'

// import whyDidYouRender from '@welldone-software/why-did-you-render'

//   whyDidYouRender(React, {
//     collapseGroups: true,
//     include: [/.*/],
//     // exclude: [/^Link/, /^Route/, /^BrowserRouter/],
//   });

const rootElement = document.getElementById('root')
// wait for auth, then init app
store.dispatch(initAuth()).then(() => {
  store.dispatch(setInitial())

  const renderMethod = module.hot ? render : hydrate

  renderMethod(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement
  )
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
