import { PLAYER_SET_VOLUME, PLAYER_TOGGLE_MUTE, PLAYER_TOGGLE_PLAYING, PLAYER_SET_LOADING } from '../constants/action-types'

export const setVolume = volume => ({
  type: PLAYER_SET_VOLUME,
  volume
})

export const toggleMute = () => ({
  type: PLAYER_TOGGLE_MUTE
})

export const setIsPlaying = (playing) => ({
  type: PLAYER_TOGGLE_PLAYING,
  playing
})

export const setLoading = loading => ({
  type: PLAYER_SET_LOADING,
  loading
})