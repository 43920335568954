import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import Disqus from 'disqus-react'
import { DISQUS_SHORTNAME } from '../../constants/common'

import './EpisodeInfo.scss'

const EpisodeInfo = ({ episode }) => {
  const [disqusConfig, setDisqusConfig] = useState(null)

  useEffect(
    () => {
      setDisqusConfig({
        url: window.location.href,
        identifier: episode.id,
        title: episode.title
      })
    },
    [episode.id, episode.title]
  )

  return (
    <div className="b-episode">
      <Helmet>
        <title>{episode.title}</title>
        <meta name="description" content={episode.description} />
      </Helmet>
      <h1 className="b-episode__title">{episode.title}</h1>
      <div className="u-wysiwyg" dangerouslySetInnerHTML={{ __html: episode.descriptionHtml }} />

      <div className="b-podcast__comments">{disqusConfig ? <Disqus.DiscussionEmbed shortname={DISQUS_SHORTNAME} config={disqusConfig} /> : ''}</div>
    </div>
  )
}

export default EpisodeInfo
