import React, { useRef, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { pause, updateMeta, play } from '../../actions/episode'
import { setVolume, setIsPlaying, setLoading } from '../../actions/player'

const mapStateToProps = state => ({
  sound: state.sound
})

const mapDispatchToProps = dispatch => ({
  pause: () => dispatch(pause()),
  play: () => dispatch(play()),
  updateMeta: meta => dispatch(updateMeta(meta)),
  setVolume: volume => dispatch(setVolume(volume)),
  setIsPlaying: playing => dispatch(setIsPlaying(playing)),
  setLoading: loading => dispatch(setLoading(loading))
})

const Player = ({ sound, pause, setIsPlaying, updateMeta, setLoading }) => {
  const playerRef = useRef(null)

  // Контроллирует будут ли обрабатываться события с <audio>
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (sound.playing) {
      setReady(true)
      playerRef.current.play()
    } else {
      setReady(false)
      playerRef.current.pause()
    }
  }, [playerRef, sound.playing, sound.soundUrl])

  useEffect(() => {
    if (sound.meta.changePosition) {
      playerRef.current.currentTime = sound.meta.currentTime
      updateMeta({ changePosition: false })
    }
  }, [sound.meta, updateMeta])

  useEffect(() => {
    playerRef.current.volume = sound.volume / 100
  }, [sound.volume])

  useEffect(() => {
    playerRef.current.muted = sound.muted
  }, [sound.muted])

  // const handleDurationChange = e => {
  //   const { duration = 0 } = e.target
  //   updateMeta({ duration })
  // }

  const handleEnded = e => {
    // if (ready) {
      pause()
      updateMeta({ changePosition: true, currentTime: 0 })
      setReady(false)
    // }
  }

  const handleTimeupdate = e => {
    if (ready) {
      const { currentTime = 0 } = e.target
      updateMeta({ currentTime })
    }
  }

  const handlePause = () => {
    if (sound.playing) {
      setIsPlaying(false)
    }
  }

  const handleLoadStart = e => {
    setLoading(true)
  }

  const handleLoadedMetadata = () => {
    const currentTime = sound.meta.currentTime
    const duration = playerRef.current.duration
    setIsPlaying(true)
    updateMeta({ currentTime, changePosition: true, duration })
    setLoading(false)
    setReady(true)
  }

  return (
    <React.Fragment>
      <audio
        onTimeUpdate={handleTimeupdate}
        onEnded={handleEnded}
        // onDurationChange={handleDurationChange}
        onLoadedMetadata={handleLoadedMetadata}
        onPause={handlePause}
        onLoadStart={handleLoadStart}
        // onPlay={handlePlay}
        src={sound.soundUrl}
        ref={playerRef}
        volume={sound.volume / 100}
        muted={sound.muted}
      />
    </React.Fragment>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Player)
