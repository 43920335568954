import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import PlaylistPlayButton from './PlaylistPlayButton'
import PlayerProgressbar from '../player/PlayerProgressbar'

import './Playlist.scss'
import PlayerProgressTime from '../player/PlayerProgressTime'
// import { setAsCurrent } from '../../actions/episode'
import { selectEpisode } from '../../actions/podcasts'

const mapStateToProps = state => ({
  episodeId: state.sound.episodeId,
  selectedEpisodeId: state.podcast.selectedEpisode && state.podcast.selectedEpisode.id
})

const mapDispatchToProps = dispatch => ({
  // selectEpisode: (episode, stop) => dispatch(setAsCurrent(episode, stop)),
  selectEpisode: episode => dispatch(selectEpisode(episode))
})

const Playlist = ({ podcast, episodeId, history, selectEpisode, selectedEpisodeId }) => {
  const handleEpisodeClick = (e, episode) => {
    e.preventDefault()
    selectEpisode(episode, false)
    history.push({ pathname: `/podcast/${episode.podcast_id}/episode/${episode.id}`, state: { stop: false }})
  }

  const itemClassName = (episode) => {
    const classes = ['b-playlist-item']
    if (episode.id === episodeId) {
      classes.push('b-playlist-item--playing')
    }
    if (episode.id === selectedEpisodeId) {
      classes.push('b-playlist-item--selected')
    }

    return classes.join(' ')
  }

  return (
    <div className='b-playlist'>
      {podcast.episodes.map((episode, idx) => (
        <div key={idx} className={itemClassName(episode)}>
          <PlaylistPlayButton episode={episode} />

          <Link to={{ pathname: `/podcast/${episode.podcast_id}/episode/${episode.id}`, state: { stop: false }}} className='b-playlist-item__title' onClick={e => handleEpisodeClick(e, episode)}>
            {episode.title}
            {episode.explicit ? <span className='u-explicit b-playlist-item__explicit'>!</span> : ''}
          </Link>

          {episode.id === episodeId ? (
          <React.Fragment>
          <div className='b-playlist-item__playback_progress'>
            <PlayerProgressbar episodeId={episode.id} />
          </div>
          <div className='b-playlist-item__playback_time'>
            <PlayerProgressTime episodeId={episode.id} duration={parseInt(episode.speech.duration)} alwaysShow />
          </div></React.Fragment>) : ''}
        </div>
      ))}
    </div>
  )
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Playlist)
)
