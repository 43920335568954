import React from 'react'
import { connect } from 'react-redux'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { reorderEpisode, deleteEpisode, episodeResetForm } from '../../../actions/episodeForm'
require('./PodcastFormEpisodeList.scss')

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  deleteEpisode: episode => dispatch(deleteEpisode(episode)),
  reorderEpisode: (from, to) => dispatch(reorderEpisode(from, to)),
  episodeResetForm: () => dispatch(episodeResetForm())
})

const PodcastFormEpisodeList = ({ episodes, onEdit, deleteEpisode, reorderEpisode, episodeResetForm }) => {
  const handleReorder = result => {
    if (result.source && result.destination) {
      // console.log('r', result)
      reorderEpisode(result.source.index, result.destination.index)
    }
  }

  const handleEditClick = (e, episode) => {
    e.preventDefault()
    episodeResetForm()
    onEdit(episode)
  }

  const handleDeleteClick = (e, episode) => {
    e.preventDefault()
    deleteEpisode(episode)
  }

  return (
    <div className="b-podcast-form-episode-list">
      <DragDropContext onDragEnd={handleReorder}>
        <Droppable droppableId="droppable">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {episodes.map((episode, index) => (
                <Draggable key={`${episode.id}`} index={index} draggableId={`draggable_${episode.id}`}>
                  {provided => (
                    <div className="b-podcast-form-episode-list__item" ref={provided.innerRef} {...provided.draggableProps}>
                      <button className="b-podcast-form-episode__delete_btn" onClick={e => handleDeleteClick(e, episode)}>
                        X
                      </button>
                      <span className="b-podcast-form-episode__title">{episode.title}</span>
                      <button className="b-podcast-form-episode__update_btn" onClick={e => handleEditClick(e, episode)}>
                        E
                      </button>
                      <div className="b-podcast-form-episode__replace_btn" {...provided.dragHandleProps}>
                        =
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PodcastFormEpisodeList)
