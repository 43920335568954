import { LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT } from '../constants/action-types'

export const login = payload => ({
  type: LOGIN,
  payload
})

export const loginSuccess = payload => ({
  type: LOGIN_SUCCESS,
  ...payload
})

export const loginFail = payload => {
  if (!(payload instanceof Array)) {
    return {
      type: LOGIN_FAILURE,
      errors: [payload]
    }
  } else {
    return {
      type: LOGIN_FAILURE,
      errors: payload
    }
  }
}

export const logout = () => ({
  type: LOGOUT
})