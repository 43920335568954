import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getCoverUrl } from '../helpers/file'
import { editPodcast, resetForm } from '../actions/podcastForm'
import PlayButton from './player/PlayButton'
import PlayerPlayback from './player/PlayerPlayback'
import Playlist from './podcast/Playlist'
import EpisodeInfo from './podcast/EpisodeInfo'
import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import { setH1 } from '../actions/common'
import { deletePodcast, getPodcastEpisode } from '../thunks/podcast'
import { maximizePodcast, selectEpisode } from '../actions/podcasts'

require('./Podcast.scss')

const mapStateToProps = state => ({
  canEdit(id) {
    return state.auth.loggedIn && state.auth.id === id
  },
  maximizedId: state.podcast.maximizedPodcastId,
  episode: state.podcast.selectedEpisode,
  sound: state.sound
})

const mapDispatchToProps = dispatch => ({
  editPodcast: podcast => dispatch(editPodcast(podcast)),
  resetForm: () => dispatch(resetForm()),
  setTitle: title => dispatch(setH1(title)),
  deletePodcast: id => dispatch(deletePodcast(id)),
  selectEpisode: episode => dispatch(selectEpisode(episode)),
  maximize: id => dispatch(maximizePodcast(id)),
  getPodcastEpisode: (id, episodeId, stop, currentTime) => dispatch(getPodcastEpisode(id, episodeId, stop, currentTime)),
})

const Podcast = ({ podcast, history, canEdit, maximizedId, setTitle, match, episode, deletePodcast, sound }) => {
  const handleEdit = e => {
    e.preventDefault()
    history.push(`/podcast/edit/${podcast.id}`)
  }

  const handleDelete = e => {
    e.preventDefault()
    deletePodcast(podcast.id)
    history.push('/')
  }

  const handleUpdateUrl = (podcast, episode) => {
    history.push(`/podcast/${podcast.id}/episode/${episode.id}`)
  }

  useEffect(
    () => {
      if (maximizedId !== null && podcast.id === maximizedId) {
        setTitle(podcast.title)
      }
    },
    [podcast, maximizedId, setTitle, match.url]
  )

  const isMaximized = () => podcast.id === maximizedId

  const className = `b-podcast ${podcast.id === maximizedId ? 'maximized' : maximizedId !== null ? 'hidden' : ''}`

  return (
    <div className={className}>
      {canEdit(podcast.user_id) ? (
        <div className="b-podcast-btns b-podcast-btns--left">
          <button className="b-podcast-edit-btn" onClick={handleEdit}>
            ed
          </button>
          <button className="b-podcast-delete-btn" onClick={handleDelete}>
            rm
          </button>
        </div>
      ) : (
        ''
      )}
      {isMaximized() ? (
        <Helmet>
          <title>{podcast.title}</title>
          <meta name="description" content={podcast.description} />
        </Helmet>
      ) : (
        ''
      )}

      <div className="b-podcast__container">
        <div className="b-podcast__thumb">
          <div className="b-podcast__cover_container">
            <Link to={`/podcast/${podcast.id}`}>
              <img className="b-podcast__cover" src={getCoverUrl(podcast.cover)} alt="" />
            </Link>
          </div>
          <div className="b-podcast__thumb_top">
            <h1 className="b-podcast__title">
              {isMaximized() ? podcast.title : <Link to={`/podcast/${podcast.id}`}>{podcast.title}</Link>}{' '}
              {podcast.explicit ? <span className="u-explicit b-podcast__explicit">!</span> : ''}
              {podcast.status === 0 ? <span className="u-status u-status--hidden">Отключен</span> : ''}
            </h1>
          </div>
          {podcast.episodes.length > 0 ? (
            <div className="b-podcast__thumb_bottom">
              <PlayButton podcast={podcast} onUpdateUrl={handleUpdateUrl} />
              {podcast.id === sound.podcastId ? <PlayerPlayback episodeId={sound.episodeId} duration={sound.meta.duration} /> : ''}
            </div>
          ) : (
            ''
          )}

          <div className="b-podcast__description u-wysiwyg" dangerouslySetInnerHTML={{ __html: podcast.descriptionHtml }} />
        </div>

        <div className="b-podcast__meta">
          {podcast.episodes.length > 0 ? (
            <div className="b-podcast__playlist">
              <Playlist podcast={podcast} />
            </div>
          ) : (
            'Пока нет эпизодов:('
          )}

          {isMaximized() && episode ? (
            <div className="b-podcast__episode_info">
              <EpisodeInfo episode={episode} />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Podcast))
