import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT } from '../constants/action-types'

const defaultState = {
  id: null,
  loggedIn: false,
  jwt: {},
  hasError: false,
  errors: []
}

// received jwt
export default (state = defaultState, { type, ...payload }) => {
  if (type === LOGIN_SUCCESS) {
    return {
      ...defaultState,
      ...payload,
      loggedIn: true
    }
  }

  if (type === LOGIN_FAILURE) {
    return {
      ...defaultState,
      hasError: true,
      errors: payload.errors
    }
  }

  if (type === LOGOUT) {
    return defaultState
  }

  return state
}
