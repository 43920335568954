export default values => {
  let errors = {}

  if (!values.email) {
    errors.email = 'Email is required'
  } else if (!/\w+@\w+\.\w+$/.test(values.email)) {
    errors.email = 'Email is incorrect'
  }

  if (!values.password) {
    errors.password = 'Password is required'
  } else if (values.password.length < 8) {
    errors.password = 'Password must be 8 or more characters'
  }

  return errors
}
