import PodcastService from '../services/podcast'
import { episodeUploadSuccessful, episodeUploadFailed } from '../actions/episodeForm'

export const uploadEpisode = file => async (dispatch, getState) => {
  if (file) {
    const [data, error] = await PodcastService.uploadEpisode(getState().auth.jwt, file)

    if (error) {
      dispatch(episodeUploadFailed(error))
    } else {
      dispatch(episodeUploadSuccessful(data.file_id, data.url))
    }
  }
}
