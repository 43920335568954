import { Service } from './index'

export default new class PodcastService extends Service {
  async latest(jwt) {
    const headers = {}

    if (jwt && jwt.type && jwt.token) {
      headers['Authorization'] = `${jwt.type} ${jwt.token}`
    }

    const response = await fetch(this.url('podcast'), {
      headers
    })
    const json = await response.json()

    if (json.error) {
      return [[], json.payload]
    }

    return [json.payload, null]
  }

  async one(jwt, id) {
    const response = await fetch(this.url(`podcast/${id}`), {
      method: 'get',
      headers: {
        Authorization: `${jwt.type} ${jwt.token}`
      }
    })
    const json = await response.json()

    if (json.error) {
      return [{}, json.payload]
    }
    return [json.payload, null]
  }

  async uploadCover(jwt, file) {
    const body = new FormData()
    body.append('cover', file)

    const response = await fetch(this.url('podcast/cover'), {
      method: 'post',
      body,
      headers: {
        Authorization: `${jwt.type} ${jwt.token}`
      }
    })

    const json = await response.json()

    if (json.error) {
      return [[], json.payload]
    }
    return [json.payload, null]
  }

  async uploadEpisode(jwt, file) {
    const body = new FormData()
    body.append('speech', file)

    const response = await fetch(this.url('podcast/episode/speech'), {
      method: 'post',
      body,
      headers: {
        Authorization: `${jwt.type} ${jwt.token}`
      }
    })

    const json = await response.json()

    if (json.error) {
      return [[], json.payload]
    }
    return [json.payload, null]
  }

  async savePodcast(jwt, podcast) {
    const response = await fetch(this.url('podcast'), {
      method: 'post',
      body: JSON.stringify({
        podcast: podcast
      }),
      headers: {
        Authorization: `${jwt.type} ${jwt.token}`,
        'Content-Type': 'application/json'
      }
    })

    const json = await response.json()

    if (json.error) {
      return [[], json.payload]
    }

    return [json.payload, null]
  }

  async deletePodcast(jwt, id) {
    const response = await fetch(this.url(`podcast/${id}`), {
      method: 'delete',
      headers: {
        Authorization: `${jwt.type} ${jwt.token}`,
        'Content-Type': 'application/json'
      }
    })

    const json = await response.json()

    if (json.error) {
      return [[], json.payload]
    }

    return [id, null]
  }
}()
