import { SET_TITLE_H1, SET_APP_ERROR, RESET_APP_ERROR } from '../constants/action-types'

export const setH1 = h1 => {
  return {
    type: SET_TITLE_H1,
    h1
  }
}

export const setAppError = error => {
  return {
    type: SET_APP_ERROR,
    error
  }
}

export const resetAppError = () => {
  return {
    type: RESET_APP_ERROR
  }
}
