import { EPISODE_PLAY, EPISODE_PAUSE, EPISODE_SET_CURRENT, EPISODE_UPDATE_META } from '../constants/action-types'
import { getSpeechUrl } from '../helpers/file'
import { defaultMeta } from '../constants/common'

export const play = episode => {
  return {
    type: EPISODE_PLAY,
    episode,
    soundUrl: getSpeechUrl(episode.speech)
  }
}

export const pause = episode => {
  return {
    type: EPISODE_PAUSE,
    episode
  }
}

export const setAsCurrent = (episode, stop = true, meta = defaultMeta) => {
  const payload = {
    episode,
    meta: {
      ...meta
    }
  }

  if (stop) {
    payload.playing = false
  }

  return {
    type: EPISODE_SET_CURRENT,
    ...payload
  }
}

export const updateMeta = meta => {
  // console.log(meta)
  return {
    type: EPISODE_UPDATE_META,
    ...meta
  }
}
