import {
  SET_NEW_PODCAST_DATA,
  NEW_PODCAST,
  PODCAST_FORM_SAVE_SUCCESS,
  PODCAST_FORM_SAVE_FAIL,
  EDIT_PODCAST,
  PODCAST_FORM_RESET
} from '../constants/action-types'

export const newPodcast = () => ({
  type: NEW_PODCAST
})

export const editPodcast = podcast => ({
  type: EDIT_PODCAST,
  ...podcast
})

export const setCover = cover => ({
  type: SET_NEW_PODCAST_DATA,
  cover
})

export const changePodcast = data => ({
  type: SET_NEW_PODCAST_DATA,
  ...data
})

export const savePodcastFormSuccessful = data => ({
  type: PODCAST_FORM_SAVE_SUCCESS,
  data,
  error: false
})

export const savePodcastFormFailed = errorMessage => ({
  type: PODCAST_FORM_SAVE_FAIL,
  error: true,
  errorMessage
})

export const resetForm = () => ({
  type: PODCAST_FORM_RESET
})
