import { Service } from './index'

export default new class CategoryService extends Service {
  async one(jwt, id) {
    const response = await fetch(this.url(`category/${id}`), {
      method: 'get',
      headers: {
        Authorization: `${jwt.type} ${jwt.token}`
      }
    })
    const json = await response.json()

    if (json.error) {
      return [{}, json.payload]
    }
    return [json.payload, null]
  }

  async all(jwt) {
    const headers = {}

    if (jwt && jwt.type && jwt.token) {
      headers['Authorization'] = `${jwt.type} ${jwt.token}`
    }

    const response = await fetch(this.url('category'), {
      headers
    })
    const json = await response.json()

    if (json.error) {
      return [[], json.payload]
    }

    return [json.payload, null]
  }

  async save(jwt, category) {
    const response = await fetch(this.url('category'), {
      method: 'post',
      body: JSON.stringify({
        category
      }),
      headers: {
        Authorization: `${jwt.type} ${jwt.token}`,
        'Content-Type': 'application/json'
      }
    })

    const json = await response.json()

    if (json.error) {
      return [[], json.payload]
    }

    return [json.payload, null]
  }

  async delete(jwt, id) {
    const response = await fetch(this.url(`category/${id}`), {
      method: 'delete',
      headers: {
        Authorization: `${jwt.type} ${jwt.token}`,
        'Content-Type': 'application/json'
      }
    })

    const json = await response.json()

    if (json.error) {
      return [[], json.payload]
    }

    return [id, null]
  }
}()
