import { ADD_PODCAST, SET_PODCASTS, UPDATE_PODCAST, PODCAST_MAXIMIZE, PODCAST_SET_CURRENT, DELETE_PODCAST, PODCAST_SELECT_EPISODE } from '../constants/action-types'

export const setPodcasts = payload => {
  return {
    type: SET_PODCASTS,
    podcasts: [...payload]
  }
}

export const addPodcast = payload => {
  return {
    type: ADD_PODCAST,
    ...payload
  }
}

export const updatePodcast = payload => {
  return {
    type: UPDATE_PODCAST,
    ...payload
  }
}

export const deletePodcast = podcastId => {
  return {
    type: DELETE_PODCAST,
    podcastId
  }
}

export const maximizePodcast = maximizedPodcastId => {
  return {
    type: PODCAST_MAXIMIZE,
    maximizedPodcastId
  }
}

export const setCurrentPodcast = podcast => {
  return {
    type: PODCAST_SET_CURRENT,
    podcast,
    maximizedPodcastId: podcast.id
  }
}

export const selectEpisode = episode => {
  return {
    type: PODCAST_SELECT_EPISODE,
    selectedEpisode: episode
  }
}