import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { editCategoryById } from '../../thunks/categoryForm'
import { saveCategory } from '../../thunks/categoryForm'

import CategorySelect from './categorySelect/CategorySelect'
import { editCategory } from '../../actions/categoryForm'

const mapStateToProps = state => ({
  categoryForm: state.categoryForm
})

const mapDispatchToProps = dispatch => ({
  editCategoryById: data => dispatch(editCategoryById(data)),
  editCategory: data => dispatch(editCategory(data)),
  saveCategory: data => dispatch(saveCategory(data))
})

const CategoryForm = ({ match, categoryForm, saveCategory, editCategoryById, editCategory, history }) => {

  useEffect(() => {
    if (match.params.id) {
      editCategoryById(match.params.id)
    }
  }, [match.params.id])

  const handleChange = e => {
    const { name, value } = e.target

    if (name) {
      editCategory({
        [name]: value
      })
    }
  }

  const handleSaveCategory = e => {
    e.preventDefault()
    saveCategory(categoryForm)
    history.push('/category/list')
  }

  return (
    <div className="b-category-form">
      <div>
        <div className="u-input-group">
          <input name="name" type="text" className="u-input" placeholder="Название" value={categoryForm.name  } onChange={handleChange} />
        </div>
        <div className="u-input-group">
          <textarea name="description" className="u-input" placeholder="Описание" value={categoryForm.description} onChange={handleChange} rows="2" />
        </div>
        <div className="u-input-group">
          <input
            name="rss_name"
            type="text"
            className="u-input"
            placeholder="Название в Apple Podcast"
            value={categoryForm.rss_name}
            onChange={handleChange}
            rows="2"
          />
        </div>

        <CategorySelect name="parent_id" value={categoryForm.parent_id} onChange={handleChange} exclude={categoryForm.id} />

        <button className="b-category-form__save" onClick={handleSaveCategory}>
          Сохранить
        </button>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryForm)
