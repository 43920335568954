import React from 'react'
require('./PodcastFormStatusCheckbox.scss')

export default ({ label, checked, onChange, name }) => {
  const id = `${Math.random()}_${name}`

  return (
    <div className='b-podcast-status'>
      <input name={name} className='b-podcast-status__checkbox' id={`${id}`} type='checkbox' checked={checked} onChange={onChange} />
      <label className='b-podcast-status__label' htmlFor={`${id}`}>
        {label}
      </label>
    </div>
  )
}
