import { SET_CATEGORIES, DELETE_CATEGORY } from '../constants/action-types'

export const setCategories = categories => ({
  type: SET_CATEGORIES,
  categories
})

export const deleteCategory = id => ({
  type: DELETE_CATEGORY,
  id
})
