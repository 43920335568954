import { ADD_CATEGORY, EDIT_CATEGORY, CATEGORY_FORM_SAVE_FAIL, CATEGORY_FORM_SAVE_SUCCESS } from '../constants/action-types'

const defaultState = {
  id: null,
  name: '',
  description: '',
  rss_name: '',
  parent_id: null,
  error: false,
  errorMessage: ''
}

export default (state = defaultState, { type, ...payload }) => {
  if (type === ADD_CATEGORY) {
    return { ...defaultState }
  }

  if (type === EDIT_CATEGORY) {
    return {
      ...state,
      ...payload
    }
  }

  if (type === CATEGORY_FORM_SAVE_FAIL) {
    return {
      ...state,
      ...payload,
      error: true
    }
  }

  if (type === CATEGORY_FORM_SAVE_SUCCESS) {
    return defaultState
  }

  return state
}
