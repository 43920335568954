import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from '../reducers/index'
import thunk from 'redux-thunk'

import { composeWithDevTools } from 'redux-devtools-extension'

const composeEnhancers = process.env.NODE_ENV === 'production' ? compose : composeWithDevTools({ trace: true })

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

export default store
