import React from 'react'
import { connect } from 'react-redux'

require('./CategorySelect.scss')

const mapStateToProps = state => ({
  categories: state.categories
})

const CategorySelect = ({ onChange, name, value, categories, exclude = 0 }) => {
  const handleChange = e => {
    onChange(e)
  }

  return (
    <div className="b-category-select">
      <label className="b-category-select__label">Рубрика</label>
      <select className="b-category-select__dropdown" name={name} onChange={handleChange} value={value}>
        <option value={-1}>Не выбрано</option>
        {categories.filter(category => category.id !== exclude && category.parent_id !== exclude).map(category => <option value={category.id}>{category.name}</option>)}
      </select>
    </div>
  )
}

export default connect(mapStateToProps)(CategorySelect)
