import defaultCover from '../assets/preview-input.png'

/*
  Create image url
*/
export const getImageUrl = (cover, type = 'png') => {
  if (cover && cover.uuid) {
    return `${process.env.REACT_APP_API_URL}/api/files/image/${cover.uuid}.${type}`
  }

  return defaultCover
}

export const getCoverUrl = (cover, type = 'png', style = '600x600') => {
  if (cover && cover.uuid) {
    return `${process.env.REACT_APP_API_URL}/api/files/image/${style}/${cover.uuid}.${type}`
  }

  return defaultCover
}

export const getSpeechUrl = (speech, format = 'mp3') => {
  if (speech && speech.uuid) {
    return `${process.env.REACT_APP_API_URL}/api/files/sound/${speech.uuid}.${format}`
  }

  return false
}
