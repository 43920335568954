import { EDIT_CATEGORY, ADD_CATEGORY, UPDATE_CATEGORY, CATEGORY_FORM_SAVE_SUCCESS, CATEGORY_FORM_SAVE_FAIL } from '../constants/action-types'

export const editCategory = category => ({
  type: EDIT_CATEGORY,
  ...category
})

export const updateCategory = data => ({
  type: UPDATE_CATEGORY,
  ...data
})

export const addCategory = data => ({
  type: ADD_CATEGORY,
  ...data
})

export const saveCategoryFormSuccessful = data => ({
  type: CATEGORY_FORM_SAVE_SUCCESS
  // ...data
})

export const saveCategoryFormFailed = errorMessage => ({
  type: CATEGORY_FORM_SAVE_FAIL,
  errorMessage
})
