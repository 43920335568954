import service from '../services/category'
import { saveCategoryFormFailed, updateCategory, addCategory, saveCategoryFormSuccessful, editCategory } from '../actions/categoryForm'

export const saveCategory = category => async (dispatch, getState) => {
  if (category) {
    const [data, error] = await service.save(getState().auth.jwt, category)

    if (error) {
      dispatch(saveCategoryFormFailed(error))
    } else {
      if (category.id) {
        dispatch(updateCategory(data))
      } else {
        dispatch(addCategory(data))
      }

      dispatch(saveCategoryFormSuccessful(data))
    }
  }
}

export const editCategoryById = (id) => async (dispatch, getState) => {
  const [data, error] = await service.one(getState().auth.jwt, id)

  if (error) {
    dispatch(editCategory())
  } else {
    dispatch(editCategory(data))
  }
}