import React from 'react'
import { connect } from 'react-redux'
import { setVolume } from '../../actions/player'

import BaseTrackpath from './BaseTrackpath'

import './VolumeControl.scss'

const mapStateToProps = state => ({
  volume: state.sound.volume
})
const mapDispatchToProps = dispatch => ({
  setVolume: volume => dispatch(setVolume(volume))
})

const VolumeControl = ({ className = '', volume, setVolume }) => {
  const getCursorOffsetX = ({event, ref, progressRef}) => {
    // prevent if touch device
    if (event.type.startsWith('touch')) {
      return progressRef.current.offsetLeft
    }
    // track path
    if (event.target === ref.current) {
      return event.nativeEvent.offsetX
    } else {
      return progressRef.current.offsetLeft
    }
  }

  const handleChange = ({percent}) => {
    setVolume(percent)
  }

  const updateCursor = ({progressRef, percent}) => {
    progressRef.current.style.left = `${percent}%`
  }

  return (
    <React.Fragment>
      <BaseTrackpath className={`${className} b-player-volume`} value={volume} onChange={handleChange} updateCursor={updateCursor} getCursorOffsetX={getCursorOffsetX} />
    </React.Fragment>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VolumeControl)
