import React from 'react'
import { connect } from 'react-redux'
import { play, pause } from '../../actions/episode'
import { withRouter } from 'react-router-dom'
import './PlaylistPlayButton.scss'

const mapStateToProps = state => ({
  playing: state.sound.playing,
  soundUrl: state.sound.soundUrl,
  podcastId: state.sound.podcastId,
  episodeId: state.sound.episodeId,
  sound: state.sound
})
const mapDispatchToProps = dispatch => ({
  play: episode => dispatch(play(episode)),
  pause: () => dispatch(pause())
})

const PlaylistPlayButton = ({ sound, episode, play, pause, history }) => {
  const handlePlayClick = () => {
    play(episode)

    if (sound.episodeId !== episode.id) {
      history.push(`/podcast/${episode.podcast_id}/episode/${episode.id}`)
    }
  }
  const handlePauseClick = () => {
    pause()
  }

  const buttonClass =
    sound.episodeId === episode.id
      ? `b-player__btn ${sound.loading ? 'b-player__btn--pause b-player__btn--loading' : sound.playing ? 'b-player__btn--pause' : 'b-player__btn--play'}`
      : 'b-player__btn b-player__btn--play'
  const buttonHandler = sound.episodeId === episode.id ? (sound.playing ? handlePauseClick : handlePlayClick) : handlePlayClick

  return (
    <div className="b-playlist__player">
      <button className={buttonClass} disabled={sound.loading} onClick={buttonHandler} />
    </div>
  )
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PlaylistPlayButton)
)
