import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import './PlayerPlayingNow.scss'

const PlayerPlayingNow = ({ podcast, episode, className }) => {
  return (
    <React.Fragment>
      {podcast && (
        <div className={`${className} b-player-playing-now`}>
          <Link to={{ pathname: `/podcast/${podcast.id}`, state: { stop: false } }} className="b-player-playing-now__podcast">
            {podcast.title}
          </Link>
          {episode && (
            <Link to={{ pathname: `/podcast/${podcast.id}/episode/${episode.id}`, state: { stop: false, select: true } }} className="b-player-playing-now__episode">
              {episode.title}
            </Link>
          )}
        </div>
      )}
    </React.Fragment>
  )
}

export default withRouter(PlayerPlayingNow)