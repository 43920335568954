import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { maximizePodcast, selectEpisode } from '../actions/podcasts'
import { getPodcastEpisode, getPodcast } from '../thunks/podcast'
import { getTimeFromHash } from '../helpers/time'
import { updateMeta } from '../actions/episode'

import Podcast from './Podcast'

const mapStateToProps = state => ({
  episode: state.sound.podcastId === state.podcast.maximizedPodcastId && state.sound.episode,
  podcast: state.podcast.podcast
})

const mapDispatchToProps = dispatch => ({
  maximize: id => dispatch(maximizePodcast(id)),
  getPodcast: id => dispatch(getPodcast(id)),
  getPodcastEpisode: (id, episodeId, stop, currentTime) => dispatch(getPodcastEpisode(id, episodeId, stop, currentTime)),
  updateMeta: meta => dispatch(updateMeta(meta)),
  selectEpisode: episode => dispatch(selectEpisode(episode))
})

const EpisodePage = ({ location, match, maximize, getPodcast, getPodcastEpisode, podcast, episode, updateMeta, selectEpisode }) => {
  const { id, episodeId } = match.params
  const stop = location.state && location.state.stop !== false

  useEffect(() => {
    /*
      Change playback position if got time from hash
    */
    const currentTime = getTimeFromHash(location.hash)

    if (currentTime !== null) {
      updateMeta({ currentTime, changePosition: true })
    }
  }, [location.hash])

  useEffect(() => {
    if (episodeId) {
      const currentTime = getTimeFromHash(location.hash)
      const meta = currentTime !== null ? { currentTime } : null
      getPodcastEpisode(parseInt(id), parseInt(episodeId), stop, meta)
    } else if (id) {
      getPodcast(parseInt(id), stop)
    }
    maximize(id)
  }, [])

  return (
    <div>
      <Podcast podcast={podcast} episode={episode} />
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EpisodePage)
