import React from 'react'
import { secondsToTime } from '../../helpers/time'
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  sound: state.sound
})

const PlayerProgressTime = ({ episodeId, sound, duration = 0, alwaysShow = false }) => {
  const isAvailable = () => episodeId !== null && episodeId === sound.episodeId
  return (
    <React.Fragment>
      {isAvailable()
        ? `${secondsToTime(sound.meta.currentTime)} / ${secondsToTime(sound.meta.duration || parseInt(duration)) || 0}`
        : alwaysShow
          ? `00:00 / ${secondsToTime(parseInt(duration))}`
          : ''}
    </React.Fragment>
  )
}

export default connect(mapStateToProps)(PlayerProgressTime)
