import React, { useRef, useState } from 'react'
import { connect, useStore } from 'react-redux'
import { uploadEpisode } from '../../../thunks/episodeForm'
import { updateEpisodeName, updateEpisodeFileName, updateEpisodeDescription, updateEpisodeForm } from '../../../actions/episodeForm'
import PodcastFormStatusCheckbox from '../status/PodcastFormStatusCheckbox'

require('./PodcastFormEpisodeUploader.scss')

const mapStateToProps = state => ({
  episodeForm: state.episodeForm
})
const mapDispatchToProps = dispatch => ({
  uploadEpisode: file => dispatch(uploadEpisode(file)),
  updateEpisodeName: name => dispatch(updateEpisodeName(name)),
  updateEpisodeDescription: description => dispatch(updateEpisodeDescription(description)),
  updateEpisodeFileName: name => dispatch(updateEpisodeFileName(name)),
  updateEpisodeForm: data => dispatch(updateEpisodeForm(data))
})

const validationDefaultState = {
  file_id: {
    valid: true,
    message: ''
  },
  title: {
    valid: true,
    message: ''
  }
}

const PodcastEpisodeUploader = ({
  updateEpisodeName,
  updateEpisodeDescription,
  episodeForm,
  onSubmit,
  uploadEpisode,
  updateEpisodeFileName,
  updateEpisodeForm
}) => {
  const fileRef = useRef(null)
  const [validationError, setValidationError] = useState(validationDefaultState)

  const store = useStore()

  const handleChange = e => {
    updateEpisodeName(e.target.value)
  }
  const handleDescriptionChange = e => {
    updateEpisodeDescription(e.target.value)
  }
  const handleCheckboxChange = e => {
    const { name, checked } = e.target

    if (name) {
      updateEpisodeForm({
        [name]: !!checked
      })
    }
  }

  const validateAndSubmit = () => {
    // get fresh episodeForm data, because it's not updating with mapState...
    const { episodeForm } = store.getState()
    let formValid = true

    setValidationError(validationDefaultState)

    if (!episodeForm.file_id) {
      setValidationError({
        ...validationError,
        file_id: {
          valid: false,
          message: 'Не выбран файл для загрузки'
        }
      })
      formValid = false
    }

    if (/^\s*$/.test(episodeForm.title)) {
      setValidationError({
        ...validationError,
        title: {
          valid: false,
          message: 'Заголовок не может быть пустым!'
        }
      })
      formValid = false
    }

    if (formValid) {
      onSubmit(episodeForm)
    }
  }

  const handleUploadAndSubmit = async e => {
    e.preventDefault()

    if (fileRef.current && fileRef.current.files.length > 0) {
      await uploadEpisode(fileRef.current.files[0])
    }

    validateAndSubmit()
  }

  const handleFileChange = e => {
    if (e.target.files.length > 0) {
      updateEpisodeFileName(e.target.files[0].name)
    }
  }

  return (
    <div className='b-podcast-form-episode-uploader'>
      <form onSubmit={handleUploadAndSubmit} className='b-podcast-form-episode-uploader__form'>
        <div className='b-podcast-form-episode-uploader__container'>
          <input type='text' className='u-input' placeholder='Тема эпизода' value={episodeForm.title} onChange={handleChange} />

          {validationError.title.valid ? '' : validationError.title.message}

          <textarea
            className='u-input'
            placeholder='Описание эпизода'
            onChange={handleDescriptionChange}
            value={episodeForm.description ? episodeForm.description : ''}
          />

          <PodcastFormStatusCheckbox label='Содержит мат и прочее' name='explicit' checked={episodeForm.explicit} onChange={handleCheckboxChange} />

          <label className='b-podcast-form-episode-uploader__file'>
            <input type='file' ref={fileRef} onChange={handleFileChange} />
            Файл {episodeForm.filename ? `(${episodeForm.filename})` : ''}
          </label>
          {validationError.file_id.valid ? '' : validationError.file_id.message}

          {episodeForm.error ? <h3>{episodeForm.errorMessage}</h3> : ''}
        </div>

        <button className='b-podcast-form-episode-uploader__add' type='submit'>
          {episodeForm.isNew ? 'Добавить+' : 'Обновить'}
        </button>
      </form>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PodcastEpisodeUploader)
