import { PODCAST_SELECT_EPISODE, PODCAST_MAXIMIZE, PODCAST_SET_CURRENT, EPISODE_PLAY } from '../constants/action-types'

const defaultState = {
  maximizedPodcastId: null,
  selectedEpisode: null,
  podcast: {
    episodes: []
  }
}

export default (state = defaultState, { type, ...payload }) => {
  if (type === PODCAST_MAXIMIZE) {
    return {
      ...state,
      ...payload
    }
  }

  if (type === PODCAST_SELECT_EPISODE) {
    return {
      ...state,
      ...payload
    }
  }

  if (type === PODCAST_SET_CURRENT) {
    return {
      ...state,
      ...payload
    }
  }

  if (type === EPISODE_PLAY) {
    if (state.maximizedPodcastId === payload.episode.podcast_id) {
      return {
        ...state,
        selectedEpisode: payload.episode
      }
    }
    return state
  }

  return state
}
