import React from 'react'
import { Link } from 'react-router-dom'

const Category = ({ data, onDelete }) => {
  const handleDelete = e => {
    e.preventDefault()
    onDelete(data.id)
  }

  return (
    <div>
      <span>Name: {data.name}</span>
      <button onClick={handleDelete}>Delete</button>
      <Link to={`/category/edit/${data.id}`}>EDIT</Link>
    </div>
  )
}

export default Category