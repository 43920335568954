import React from 'react'
import { connect } from 'react-redux'
import { deleteCategory } from '../../thunks/categories'

import './CategoriesList.scss'

import Category from './Category'

const mapStateToProps = state => ({
  categories: state.categories
})

const mapDispatchToProps = dispatch => ({
  deleteCategory: category => dispatch(deleteCategory(category))
})

const CategoriesList = ({ categories, deleteCategory }) => {
  // const firstLevelCategories = categories => categories.filter(cat => !cat.parent_id)
  const makeCategoriesTree = categories => {
    const result = {}

    categories.sort(category => !!category.parent_id).forEach(category => {
      if (!category.parent_id) {
        result[category.id] = Object.assign({ childrens: [] }, result[category.id], category)
      } else if (result[category.parent_id]) {
        result[category.parent_id].childrens.push(category)
      }
    })

    return result
  }

  return (
    <div className="b-categories-list">
      {Object.values(makeCategoriesTree(categories)).map(category => (
        <div className="b-categories-list__item">
        <Category data={category} onDelete={deleteCategory}></Category>
        {category.childrens.map(children => (
          <div className="b-categories-list__child_item">
            <Category data={children} onDelete={deleteCategory}></Category>
          </div>
        ))}
        </div>
      ))}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesList)
