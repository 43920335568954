import React, { useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import useForm from '../../helpers/form/useForm'
import validate from '../../helpers/form/LoginFormValidationRules'
import { login, register } from '../../thunks/auth'

require('./AuthForm.scss')

const mapStateToProps = state => ({
  hasAuthErrors: state.auth.hasError,
  authErrors: state.auth.errors
})

const mapDispatchToProps = dispatch => ({
  login: ({ email, password }) => dispatch(login(email, password)),
  register: ({ email, password }) => dispatch(register(email, password))
})

const AuthForm = ({ login, register, hasAuthErrors, authErrors, history }) => {
  const modeRegister = useRef(null)

  const validFormSubmitCallback = (...payload) => {
    if (modeRegister.current.checked) {
      register(...payload)
    } else {
      login(...payload)
    }

    history.push('/')
  }

  const initialValues = {
    email: '',
    password: ''
  }

  const { values, errors, setErrors, handleChange, handleSubmit } = useForm(initialValues, validFormSubmitCallback, validate)

  /*
    Server errors handling
  */
  useEffect(
    () => {
      if (hasAuthErrors) {
        let serverErrors = {}
        authErrors.forEach(error => {
          if (typeof error === 'string') {
            serverErrors['form'] = error
          } else {
            serverErrors[error.field] = error.message
          }
        })
        setErrors({ ...errors, ...serverErrors })
      }
    },
    [hasAuthErrors, authErrors]
  )

  return (
    <div className='b-auth-form'>
      <label>
        <input type='checkbox' ref={modeRegister} /> Register
      </label>

      {errors.form && <p>{errors.form}</p>}

      <form onSubmit={handleSubmit}>
        {errors.email && <p>{errors.email}</p>}
        <input type='text' name='email' placeholder='E-mail' value={values.email || ''} onChange={handleChange} />

        {errors.password && <p>{errors.password}</p>}
        <input type='password' name='password' placeholder='password' value={values.password || ''} onChange={handleChange} />

        <button type='submit'>Authorize</button>
      </form>
    </div>
  )
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AuthForm)
)
