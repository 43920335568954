import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import CategoriesList from './CategoriesList'

const mapStateToProps = state => ({
  // categories: state.categories
})

const mapDispatchToProps = dispatch => ({
  // deleteCategory: category => dispatch(deleteCategory(category))
})

const Categories = () => {
  return (
    <div className="b-categories">
      <Link to={'/category/create'}>Добавить +</Link>

      <CategoriesList></CategoriesList>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Categories)
