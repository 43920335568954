import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { play, pause, setAsCurrent } from '../../actions/episode'

import './PlayButton.scss'

const mapStateToProps = state => ({
  sound: state.sound,
  playing: state.sound.playing,
  episodeId: state.sound.episodeId,
  podcastId: state.sound.podcastId,
  maximizedId: state.podcast.maximizedPodcastId
})
const mapDispatchToProps = dispatch => ({
  play: episode => dispatch(play(episode)),
  pause: () => dispatch(pause()),
  setAsCurrent: (episode, settings) => dispatch(setAsCurrent(episode, settings))
})

const PlayButton = ({ sound, podcast, play, pause, maximizedId, setAsCurrent, onUpdateUrl = () => {} }) => {
  const [soundIdx, setSoundIdx] = useState(0)

  useEffect(() => {
    const episodeIndex = (podcast && podcast.episodes.findIndex(episode => episode.id === sound.episodeId)) || 0
    const index = episodeIndex >= 0 ? episodeIndex : 0
    setSoundIdx(index)
  }, [sound.episodeId, podcast])

  const updateUrl = episode => {
    if (maximizedId === podcast.id) {
      onUpdateUrl(podcast, episode)
    }
  }

  const handlePlayClick = e => {
    const episode = podcast.episodes[soundIdx]
    play(episode)
    updateUrl(episode)
  }
  const handlePauseClick = e => {
    pause()
  }
  const handleNextClick = e => {
    const next = soundIdx + 1 > podcast.episodes.length - 1 ? 0 : soundIdx + 1
    setSoundIdx(next)

    if (sound.playing) {
      play(podcast.episodes[next])
    } else {
      setAsCurrent(podcast.episodes[next])
    }
    updateUrl(podcast.episodes[next])
  }
  const handlePrevClick = e => {
    const next = soundIdx - 1 < 0 ? podcast.episodes.length - 1 : soundIdx - 1
    setSoundIdx(next)

    if (sound.playing) {
      play(podcast.episodes[next])
    } else {
      setAsCurrent(podcast.episodes[next])
    }

    updateUrl(podcast.episodes[next])
  }

  // const showPlayingNow = () => podcastId && podcast.id === podcastId
  const showPrevNextBtn = () => podcast && podcast.episodes && podcast.episodes.length > 1

  const buttonClass =
    podcast && sound.podcastId === podcast.id
      ? `b-player__btn ${sound.loading ? 'b-player__btn--pause b-player__btn--loading' : sound.playing ? 'b-player__btn--pause' : 'b-player__btn--play'}`
      : 'b-player__btn b-player__btn--play'
  const buttonHandler = podcast && sound.podcastId === podcast.id ? (sound.playing ? handlePauseClick : handlePlayClick) : handlePlayClick

  return (
    <React.Fragment>
      {podcast ? (
        <div className="b-player__btns">
          {showPrevNextBtn() ? <button className="b-player__btn b-player__btn--prev" onClick={handlePrevClick} /> : ''}

          <button className={buttonClass} disabled={sound.loading} onClick={buttonHandler}></button>

          {showPrevNextBtn() ? <button className="b-player__btn b-player__btn--next" onClick={handleNextClick} /> : ''}
        </div>
      ) : (
        ''
      )}
    </React.Fragment>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayButton)
