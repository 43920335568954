const config = require('../config/api.json')

export class Service {
  /*
    Create url
  */
  url (method, getParams = null) {
    const params = this.constructor.buildQueryString(getParams)
    return `${process.env.REACT_APP_API_URL}${config.api}/${method}${params}`
  }

  /*
    Build query string
  */
  static buildQueryString (params) {
    if (params) {
      const keys = Object.keys(params)
      return keys.length ? '?' + keys.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])).join('&') : ''
    }
    return ''
  }
}
