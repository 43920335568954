import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import LogoutButton from './auth/LogoutButton'

import { ReactComponent as AppLogo } from '../assets/logo.svg'
import './Header.scss'

const mapStateToProps = state => ({
  loggedIn: state.auth.loggedIn,
  playing: state.sound.playing
})

const Header = ({ loggedIn, playing }) => {
  const logoClassName = playing ? 'playing' : ''
  return (
    <header className="b-site-header">
      <Link to="/" className="b-site-header__logo_link">
        <AppLogo className={`b-site-header__logo ${logoClassName}`} />
        {/* <img className='b-site-header__logo' src={AppLogo} alt='Ikfi.pod' /> */}
      </Link>

      <ol className="b-site-header__menu">
        <li>
          <Link to="/">Свежак</Link>
        </li>
        {loggedIn ? (
          <React.Fragment>
            <li>
              <Link to="/podcast/create">Добавить +</Link>
            </li>
            <li>
              <Link to="/category/list">Категория</Link>
            </li>
          </React.Fragment>
        ) : (
          ''
        )}
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://ikfi.ru">
            Сайт
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://blog.ikfi.ru">
            Блог
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://freeocart.ikfi.ru">
            Freeocart
          </a>
        </li>
      </ol>

      {loggedIn ? (
        <div>
          <Link className="s" to="/user">
            Кабинет
          </Link>
          <LogoutButton />
        </div>
      ) : (
        ''
      )}
    </header>
  )
}

export default connect(mapStateToProps, null)(Header)
