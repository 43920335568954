import {
  selectEpisode,
  setPodcasts,
  setCurrentPodcast,
  deletePodcast as deletePodcastAction
} from '../actions/podcasts'

import PodcastService from '../services/podcast'
import { setAsCurrent } from '../actions/episode'
import { setAppError } from '../actions/common'

export const getPodcasts = () => async (dispatch, getState) => {
  const [data, error] = await PodcastService.latest(getState().auth.jwt)
  if (!error) {
    dispatch(setPodcasts(data))
  } else {
    console.error('ep', error)
    dispatch(setAppError(error))
  }
}

export const getPodcast = id => async (dispatch, getState) => {
  const [data, error] = await PodcastService.one(getState().auth.jwt, id)
  if (!error) {
    dispatch(setCurrentPodcast(data))
  } else {
    console.error('e', error)
    dispatch(setAppError(error))
  }
}

export const getPodcastEpisode = (id, episodeId, stop = true, customMeta = null) => async (dispatch, getState) => {
  const meta = customMeta || getState().sound.meta

  const [data, error] = await PodcastService.one(getState().auth.jwt, id)
  if (!error) {
    dispatch(setCurrentPodcast(data))
    if (episodeId) {
      const episode = data.episodes.find(episode => episode.id === episodeId)
      if (episode) {
        dispatch(setAsCurrent(episode, stop, meta))
        dispatch(selectEpisode(episode))
      } else {
        dispatch(setAppError('Эпизод не найден!'))
      }
    }
  } else {
    dispatch(setAppError(error))
  }
}

export const deletePodcast = id => async (dispatch, getState) => {
  const [data, error] = await PodcastService.deletePodcast(getState().auth.jwt, id)

  if (!error) {
    dispatch(deletePodcastAction(data))
  } else {
    dispatch(setAppError(error))
  }
}
