import AuthService from '../services/auth'
import { loginSuccess, loginFail, logout as logoutAction } from '../actions/login'
import { getPodcasts } from './podcast'

const authCallback = (dispatch, auth, error) => {
  if (!error) {
    localStorage['stored-jwt'] = JSON.stringify({ ...auth })
    dispatch(loginSuccess(auth))
    // reload all podcasts to show own disabled
    dispatch(getPodcasts())
  } else {
    dispatch(logout())
    dispatch(loginFail(error))
  }
}

/*
  Авторизация через jwt
*/
export const initAuth = () => async dispatch => {
  // load jwt from localStorage and initialize auth module
  if (localStorage['stored-jwt']) {
    try {
      const { jwt, id } = JSON.parse(localStorage['stored-jwt'])
      const [auth, error] = await AuthService.authenticate(jwt)
      authCallback(dispatch, { ...auth, id }, error)
    }
    catch (e) {
      dispatch(logout())
      dispatch(loginFail('Сессия истекла'))
    }
  }
}

export const login = (email, password) => async dispatch => {
  const [auth, error] = await AuthService.login(email, password)
  authCallback(dispatch, auth, error)
}

export const register = (email, password) => async dispatch => {
  const [auth, error] = await AuthService.register(email, password)
  authCallback(dispatch, auth, error)
}

export const logout = () => dispatch => {
  localStorage.clear()
  dispatch(logoutAction())
  // reload podcasts
  dispatch(getPodcasts())
}
