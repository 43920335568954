import {
  EPISODE_PLAY,
  EPISODE_PAUSE,
  EPISODE_SET_CURRENT,
  EPISODE_UPDATE_META,
  DELETE_PODCAST,
  PLAYER_SET_VOLUME,
  PLAYER_TOGGLE_MUTE,
  PLAYER_TOGGLE_PLAYING,
  PLAYER_SET_LOADING
} from '../constants/action-types'
import { defaultMeta } from '../constants/common'

const defaultState = {
  podcastId: null,
  episodeId: null,
  playing: false,
  soundUrl: null,
  episode: null,
  volume: 100,
  muted: false,
  loading: false,
  meta: {
    ...defaultMeta
  }
}

export default (state = defaultState, { type, ...payload }) => {
  if (type === PLAYER_TOGGLE_PLAYING) {
    return {
      ...state,
      ...payload
    }
  }

  if (type === EPISODE_PLAY) {
    const { episode, soundUrl } = payload
    return {
      ...state,
      podcastId: episode.podcast_id,
      episodeId: episode.id,
      episode,
      soundUrl,
      playing: true,
      meta: {
        ...defaultMeta,
        ...state.meta,
        duration: episode.speech.duration
      }
    }
  }

  if (type === EPISODE_PAUSE) {
    return {
      ...state,
      playing: false
    }
  }

  if (type === PLAYER_SET_LOADING) {
    return {
      ...state,
      ...payload
    }
  }

  if (type === EPISODE_SET_CURRENT) {
    const { episode } = payload

    return {
      ...defaultState,
      ...state,
      podcastId: episode.podcast_id,
      episodeId: episode.id,
      ...payload,
      meta: {
        ...defaultMeta,
        ...state.meta,
        ...payload.meta,
        duration: episode.speech.duration
      }
    }
  }

  // stop on podcast delete
  if (type === DELETE_PODCAST) {
    if (state.podcastId === payload.podcastId) {
      return defaultState
    }
    return state
  }

  if (type === EPISODE_UPDATE_META) {
    return {
      ...state,
      meta: {
        ...state.meta,
        ...payload
      }
    }
  }

  if (type === PLAYER_SET_VOLUME) {
    return {
      ...state,
      muted: false,
      ...payload
    }
  }

  if (type === PLAYER_TOGGLE_MUTE) {
    return {
      ...state,
      muted: !state.muted
    }
  }

  return state
}
