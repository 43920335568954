export const SECONDS_IN_MINUTE = 60
export const MINUTES_IN_HOUR = 60

export const secondsToTime = length => {
  if (isNaN(length)) {
    return '00:00'
  }

  const hours = Math.floor(length / 3600)
  const minutes = Math.floor(length / 60) % 60
  const seconds = Math.floor(length - minutes * 60 - hours * 3600)
  if (hours > 0) {
    return `${hours.toString().padStart(2, 0)}:${minutes.toString().padStart(2, 0)}:${seconds.toString().padStart(2, 0)}`
  }
  return `${minutes.toString().padStart(2, 0)}:${seconds.toString().padStart(2, 0)}`
}

export const timeToSeconds = time => {
  if (/^\d{2}:\d{2}$/.test(time)) {
    const [ minutes, seconds ] = time.split(':')
    return parseInt(minutes * SECONDS_IN_MINUTE) + parseInt(seconds)
  }
  else if (/^\d{2}:\d{2}:\d{2}$/.test(time)) {
    const [ hours, minutes, seconds ] = time.split(':')
    return parseInt(hours * SECONDS_IN_MINUTE * MINUTES_IN_HOUR) + parseInt(minutes * SECONDS_IN_MINUTE) + parseInt(seconds)
  }
  return 0
}

export const getTimeFromHash = (hash) => {
  const matches = hash.match(/^#(\d{2}:\d{2}(:\d{2})?)$/)
  if (matches && matches.length > 1) {
    return timeToSeconds(matches[1])
  }
  return null
}