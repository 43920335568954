import React from 'react'
import { connect } from 'react-redux'
import { logout } from '../../thunks/auth'

import './LogoutButton.scss'

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
})

const LogoutButton = ({ logout }) => {
  const handleClick = e => {
    e.preventDefault()
    logout()
  }

  return (
    <button className='b-logout-btn' onClick={handleClick}>
      Выйти
    </button>
  )
}

export default connect(
  null,
  mapDispatchToProps
)(LogoutButton)
