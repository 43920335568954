import { USER_SET_DATA } from '../constants/action-types'

const defaultState = {
  info: {
    author: '',
    owner: {
      name: '',
      email: ''
    }
  }
}

export default (state = defaultState, { type, ...payload }) => {
  if (type === USER_SET_DATA) {
    return {
      ...state,
      info: {
        ...state.info,
        ...payload
      }
    }
  }

  return state
}
