import React, { useEffect } from 'react'
import { connect } from 'react-redux'
// import { getPodcasts } from '../thunks/podcast'
import Podcast from './Podcast'
import { maximizePodcast } from '../actions/podcasts'
import Helmet from 'react-helmet'
// import PageHeader from './PageHeader'
import { setH1 } from '../actions/common'

const mapStateToProps = state => ({
  podcasts: state.podcasts,
  sound: state.sound
})
const mapDispatchToProps = dispatch => {
  return {
    // getPodcasts: () => dispatch(getPodcasts()),
    maximize: id => dispatch(maximizePodcast(id)),
    setTitle: title => dispatch(setH1(title)),
  }
}

const PodcastList = ({ location, podcasts, maximize, setTitle, selectEpisode, sound }) => {
  useEffect(() => {
    // if (match.params.id) {
    //   const podcast = podcasts.filter(p => p.id === match.params.id)
    //   if (podcast) {
    //     setTitle(podcast.title)
    //   }
    //   maximize(parseInt(match.params.id))
    // } else {
    setTitle('Свежие подкасты')
    maximize(null)
    // }
  }, [])

  return (
    <section className='b-podcasts'>
      <Helmet>
        <title>Свежие подкасты</title>
        <meta name='description' content='Свежие подкасты' />
      </Helmet>
      {podcasts.map((podcast, idx) => (
        <Podcast key={`podcast_item_${idx}`} podcast={podcast} />
      ))}
    </section>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PodcastList)
