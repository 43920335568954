import PodcastService from '../services/podcast'
import { setCover, savePodcastFormSuccessful, savePodcastFormFailed, resetForm } from '../actions/podcastForm'
import { episodeResetForm } from '../actions/episodeForm'
import { newPodcast, editPodcast } from '../actions/podcastForm'
import { addPodcast, updatePodcast } from '../actions/podcasts'

export const editPodcastById = id => async (dispatch, getState) => {
  const [podcast, error] = await PodcastService.one(getState().auth.jwt, id)

  if (error) {
    dispatch(newPodcast())
  } else {
    dispatch(editPodcast(podcast))
  }
}

export const uploadPodcastCover = file => async (dispatch, getState) => {
  if (file) {
    const [data, error] = await PodcastService.uploadCover(getState().auth.jwt, file)

    if (error) {
      dispatch(setCover(null))
    } else {
      dispatch(setCover(data))
    }
  }
}

export const savePodcast = podcast => async (dispatch, getState) => {
  if (podcast) {
    const [data, error] = await PodcastService.savePodcast(getState().auth.jwt, podcast)

    if (error) {
      dispatch(savePodcastFormFailed(error))
    } else {
      if (podcast.id) {
        dispatch(updatePodcast(data))
      } else {
        dispatch(addPodcast(data))
      }

      dispatch(savePodcastFormSuccessful(data))

      dispatch(episodeResetForm())
      dispatch(resetForm())
    }
  }
}
