import {
  ADD_EPISODE,
  DELETE_EPISODE,
  UPDATE_EPISODE,
  REORDER_EPISODE,
  EPISODE_FORM_UPLOAD_SUCCESS,
  EPISODE_FORM_UPLOAD_FAILED,
  EPISODE_FORM_UPDATE_NAME,
  EPISODE_FORM_UPDATE_DESCRIPTION,
  EPISODE_FORM_UPDATE_FILENAME,
  SET_NEW_EPISODE_DATA,
  EPISODE_FORM_RESET_FORM,
  EPISODE_FORM_EDIT_EPISODE
} from '../constants/action-types'

export const updateEpisodeForm = data => ({
  type: SET_NEW_EPISODE_DATA,
  ...data
})

export const createEpisode = episode => ({
  type: ADD_EPISODE,
  ...episode
})

export const updateEpisode = episode => ({
  type: UPDATE_EPISODE,
  ...episode
})

export const deleteEpisode = episode => ({
  type: DELETE_EPISODE,
  ...episode
})

export const reorderEpisode = (from, to) => ({
  type: REORDER_EPISODE,
  from,
  to
})

export const episodeUploadSuccessful = (file_id, url) => ({
  type: EPISODE_FORM_UPLOAD_SUCCESS,
  file_id,
  url,
  error: false
})

export const episodeUploadFailed = errorMessage => ({
  type: EPISODE_FORM_UPLOAD_FAILED,
  error: true,
  errorMessage
})

export const updateEpisodeName = title => ({
  type: EPISODE_FORM_UPDATE_NAME,
  title
})

export const updateEpisodeDescription = description => ({
  type: EPISODE_FORM_UPDATE_DESCRIPTION,
  description
})

export const updateEpisodeFileName = filename => ({
  type: EPISODE_FORM_UPDATE_FILENAME,
  filename
})

export const editEpisode = episode => ({
  type: EPISODE_FORM_EDIT_EPISODE,
  ...episode
})

export const episodeResetForm = () => ({
  type: EPISODE_FORM_RESET_FORM
})
