import React from 'react'
import { getCoverUrl } from '../../../helpers/file'
require('./PodcastFormCoverUpload.scss')

const PodcastCoverUpload = ({ onUploadComplete, cover }) => {
  const handleOnChange = e => {
    onUploadComplete(e.target.files[0])
  }

  const previewStyle = {
    backgroundImage: `url(${getCoverUrl(cover)})`
  }

  return (
    <div className='b-preview' style={previewStyle}>
      <label className='b-preview-upload-btn'>
        <input type='file' onChange={handleOnChange} />
        Загрузить
      </label>
    </div>
  )
}

export default PodcastCoverUpload
