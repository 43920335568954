import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import './ErrorPage.scss'
import { setH1 } from '../actions/common'

const mapStateToProps = state => ({
  error: state.common.error
})

const mapDispatchToProps = dispatch => ({
  setH1: title => dispatch(setH1(title))
})

const ErrorPage = ({ setH1, error, location }) => {
  useEffect(() => {
    setH1('Ошибка')
  }, [])

  return (
    <div>
      <div className='b-error'>
        <span>{(error && error.error) || (location.state && location.state.error) || 'Что-то пошло не так, попробуйте вернуться на главную страницу'}</span>
      </div>
    </div>
  )
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ErrorPage)
)
