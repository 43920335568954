export const DISQUS_SHORTNAME = 'ikenfin'

export const defaultMeta = {
  duration: 0,
  currentTime: 0,
  changePosition: false
}

export const defaultCategory = {
  id: null,
  name: '',
  description: ''
}
