import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { resetForm } from '../actions/podcastForm'
import { maximizePodcast } from '../actions/podcasts'
import { editPodcastById } from '../thunks/podcastForm'

import PodcastForm from './form/PodcastForm'

const mapStateToProps = state => ({
  canEdit (id) {
    return state.auth.loggedIn && state.auth.id === id
  },
  podcastById (id) {
    return state.podcasts.filter(podcast => podcast.id === id)
  },
  maximizedId: state.podcast.maximizedPodcastId
})

const mapDispatchToProps = dispatch => ({
  editPodcast: id => dispatch(editPodcastById(id)),
  resetForm: () => dispatch(resetForm()),
  maximize: id => dispatch(maximizePodcast(id))
})

const PodcastOpenForm = ({ match, resetForm, editPodcast, maximize, podcastById }) => {
  useEffect(() => {
    if (match.params.id) {
      resetForm()
      editPodcast(match.params.id)
      maximize(null)
    }
    else {
      resetForm()
    }
  }, [match.params.id])

  return <PodcastForm />
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PodcastOpenForm)
