import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PodcastFormCoverUpload from './coverUpload/PodcastFormCoverUpload'
import PodcastFormStatusCheckbox from './status/PodcastFormStatusCheckbox'
import PodcastFormEpisodeUploader from './episodeForm/PodcastFormEpisodeUploader'
import PodcastFormEpisodeList from './episodeList/PodcastFormEpisodeList'
// import PodcastAuthor from './podcastAuthor/PodcastAuthor'

import { changePodcast, resetForm } from '../../actions/podcastForm'
import { updateEpisode, createEpisode, episodeResetForm, editEpisode } from '../../actions/episodeForm'
import { uploadPodcastCover, savePodcast } from '../../thunks/podcastForm'
import { uploadEpisode } from '../../thunks/episodeForm'
import { setH1 } from '../../actions/common'
import CategorySelect from './categorySelect/CategorySelect'

require('./PodcastForm.scss')

const mapStateToProps = state => ({
  podcastForm: state.podcastForm
})

const mapDispatchToProps = dispatch => ({
  changePodcast: data => dispatch(changePodcast(data)),
  // setPodcastCover: cover => dispatch(setCover(cover)),
  uploadPodcastCover: cover => dispatch(uploadPodcastCover(cover)),
  uploadEpisode: episode => dispatch(uploadEpisode(episode)),
  createEpisode: episode => dispatch(createEpisode(episode)),
  updateEpisode: episode => dispatch(updateEpisode(episode)),
  savePodcast: podcast => dispatch(savePodcast(podcast)),
  resetEpisodeForm: () => dispatch(episodeResetForm()),
  editEpisode: episode => dispatch(editEpisode(episode)),
  setTitle: title => dispatch(setH1(title)),
  resetForm: () => dispatch(resetForm())
})

const sortEpisodes = episodes => episodes.sort((a, b) => (a.weight >= b.weight ? 1 : -1))

const PodcastForm = ({
  podcastForm,
  uploadPodcastCover,
  changePodcast,
  createEpisode,
  updateEpisode,
  savePodcast,
  resetForm,
  resetEpisodeForm,
  editEpisode,
  history,
  setTitle
}) => {
  useEffect(
    () => {
      if (podcastForm.id) {
        setTitle('Отредачить подкаст')
      } else {
        setTitle('Добавить подкаст')
        resetForm()
        resetEpisodeForm()
      }
    },
    [podcastForm.id, resetEpisodeForm, resetForm, setTitle]
  )

  const handleChange = e => {
    const { name, value } = e.target

    if (name) {
      changePodcast({
        [name]: value
      })
    }
  }

  const handleCheckboxChange = e => {
    const { name, checked } = e.target

    if (name) {
      changePodcast({
        [name]: !!checked
      })
    }
  }

  // const [episode, setEpisode] = useState({
  //   name: '',
  //   src: '',
  //   id: null
  // })

  const handleEpisodeSubmit = episode => {
    if (!episode.isNew) {
      updateEpisode(episode)
    } else {
      createEpisode({
        ...episode,
        weight: podcastForm.episodes.length
      })
    }

    resetEpisodeForm()
  }

  const handleEdit = episode => {
    editEpisode(episode)
  }

  const handleSavePodcast = e => {
    e.preventDefault()
    // собираем все данные в кучу и отсылаем на сервер
    savePodcast(podcastForm)
    history.push('/')
  }

  return (
    <div className="b-podcast-form">
      <div>
        <PodcastFormCoverUpload cover={podcastForm.cover} onUploadComplete={uploadPodcastCover} />

        <div className="u-input-group">
          <input name="title" type="text" className="u-input" placeholder="Название подкаста" value={podcastForm.title} onChange={handleChange} rows="2" />
        </div>
        <div className="u-input-group">
          <textarea name="description" className="u-input" placeholder="Описание подкаста" rows="5" onChange={handleChange} value={podcastForm.description} />
        </div>

        <div className="u-input-group">
          <input className="u-input" name="author" type="text" placeholder="itunes:author" value={podcastForm.author} onChange={handleChange} />
        </div>
        <div className="u-input-group">
          <input className="u-input" name="contact_name" type="text" placeholder="itunes:owner:name" value={podcastForm.contact_name} onChange={handleChange} />
        </div>
        <div className="u-input-group">
          <input className="u-input" name="contact_email" type="text" placeholder="itunes:owner:email" value={podcastForm.contact_email} onChange={handleChange} />
        </div>

        <div className='u-input-group'>
          <CategorySelect name='category_id' value={podcastForm.category_id} onChange={handleChange} />
        </div>
      </div>

      <div>
        <PodcastFormEpisodeUploader onSubmit={handleEpisodeSubmit} />

        <PodcastFormStatusCheckbox label="Статус" name="status" checked={podcastForm.status} onChange={handleCheckboxChange} />

        <PodcastFormStatusCheckbox label="Содержит мат и прочее" name="explicit" checked={podcastForm.explicit} onChange={handleCheckboxChange} />

        <button className="b-podcast-form__save" onClick={handleSavePodcast}>
          Сохранить
        </button>
      </div>

      <div>
        <PodcastFormEpisodeList episodes={sortEpisodes(podcastForm.episodes)} onEdit={handleEdit} />
      </div>
    </div>
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PodcastForm))
