export const SET_TITLE_H1 = 'SET_TITLE_H1'

export const SET_APP_ERROR = 'SET_APP_ERROR'
export const RESET_APP_ERROR = 'RESET_APP_ERROR'

export const SET_INITIAL = 'SET_INITIAL'
export const ADD_PODCAST = 'ADD_PODCAST'

export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const LOGOUT = 'LOGOUT'

export const REGISTER = 'REGISTER'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILURE = 'REGISTER_FAILURE'

export const SET_PODCASTS = 'SET_PODCASTS'

export const PODCAST_MAXIMIZE = 'PODCAST_MAXIMIZE'
export const PODCAST_SET_CURRENT = 'PODCAST_SET_CURRENT'
export const PODCAST_SELECT_EPISODE = 'PODCAST_SELECT_EPISODE'

export const NEW_PODCAST = 'NEW_PODCAST'
export const EDIT_PODCAST = 'EDIT_PODCAST'
export const DELETE_PODCAST = 'DELETE_PODCAST'
export const SET_NEW_PODCAST_DATA = 'SET_NEW_PODCAST_DATA'
export const UPDATE_PODCAST = 'UPDATE_PODCAST'

export const ADD_EPISODE = 'ADD_EPISODE'
export const UPDATE_EPISODE = 'UPDATE_EPISODE'
export const DELETE_EPISODE = 'DELETE_EPISODE'
export const REORDER_EPISODE = 'REORDER_EPISODE'
export const SET_NEW_EPISODE_DATA = 'SET_NEW_EPISODE_DATA'

export const ADD_CATEGORY = 'ADD_CATEGORY'
export const EDIT_CATEGORY = 'EDIT_CATEGORY'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const SET_NEW_CATEGORY_DATA = 'SET_NEW_CATEGORY_DATA'
export const CATEGORY_FORM_SAVE_SUCCESS = 'CATEGORY_FORM_SAVE_SUCCESS'
export const CATEGORY_FORM_SAVE_FAIL = 'CATEGORY_FORM_SAVE_FAIL'
export const SET_CATEGORIES = 'SET_CATEGORIES'

export const PODCAST_FORM_SAVE_SUCCESS = 'PODCAST_FORM_SAVE_SUCCESS'
export const PODCAST_FORM_SAVE_FAIL = 'PODCAST_FORM_SAVE_FAIL'
export const PODCAST_FORM_RESET = 'PODCAST_FORM_RESET'

export const EPISODE_FORM_UPDATE_FILENAME = 'EPISODE_FORM_UPDATE_FILENAME'
export const EPISODE_FORM_UPDATE_NAME = 'EPISODE_FORM_UPDATE_NAME'
export const EPISODE_FORM_UPDATE_DESCRIPTION = 'EPISODE_FORM_UPDATE_DESCRIPTION'
export const EPISODE_FORM_UPLOAD_SUCCESS = 'EPISODE_FORM_UPLOAD_SUCCESS'
export const EPISODE_FORM_UPLOAD_FAILED = 'EPISODE_FORM_UPLOAD_FAILED'
export const EPISODE_FORM_RESET_FORM = 'EPISODE_FORM_RESET_FORM'

export const EPISODE_FORM_EDIT_EPISODE = 'EPISODE_FORM_EDIT_EPISODE'

export const EPISODE_PLAY = 'EPISODE_PLAY'
export const EPISODE_PAUSE = 'EPISODE_PAUSE'
export const EPISODE_SET_CURRENT = 'EPISODE_SET_CURRENT'
export const EPISODE_UPDATE_META = 'EPISODE_UPDATE_META'

export const PLAYER_SET_LOADING = 'PLAYER_SET_LOADING'
export const PLAYER_SET_VOLUME = 'PLAYER_SET_VOLUME'
export const PLAYER_TOGGLE_MUTE = 'PLAYER_TOGGLE_MUTE'
export const PLAYER_TOGGLE_PLAYING = 'PLAYER_TOGGLE_PLAYING'

export const USER_SET_DATA = 'USER_SET_DATA'
