import React from 'react'
import { connect } from 'react-redux'
import { pause, updateMeta } from '../actions/episode'
import { setVolume, toggleMute } from '../actions/player'

import PlayButton from './player/PlayButton'
import PlayerProgressbar from './player/PlayerProgressbar'
import PlayerProgressTime from './player/PlayerProgressTime'
import PlayerPlayingNow from './player/PlayerPlayingNow'
import VolumeControl from './player/VolumeControl'

import './BottomPlayer.scss'

import { ReactComponent as MuteIcon } from '../assets/volume.svg'

const mapStateToProps = state => ({
  sound: state.sound,
  podcast: state.podcasts.find(podcast => podcast.id === state.sound.podcastId)
})

const mapDispatchToProps = dispatch => ({
  pause: () => dispatch(pause()),
  updateMeta: meta => dispatch(updateMeta(meta)),
  setVolume: volume => dispatch(setVolume(volume)),
  toggleMute: () => dispatch(toggleMute())
})

const BottomPlayer = ({ podcast, sound, toggleMute }) => {
  const handleVolumeIconClick = e => {
    e.preventDefault()
    toggleMute()
  }

  const episode = podcast && podcast.episodes.find(episode => episode.id === sound.episodeId)

  return (
    <div className={`${podcast ? 'b-bottom-player--active' : ''} b-bottom-player`}>
      <div className="container">
        <div className="b-bottom-player__controls">
          <PlayButton podcast={podcast} />
          <PlayerProgressbar episodeId={sound.episodeId} />
          <PlayerPlayingNow className="b-bottom-player__playing-now" podcast={podcast} episode={episode} />
          <span className="b-bottom-player__progresstime">
            <PlayerProgressTime episodeId={sound.episodeId} duration={sound.meta.duration} />
          </span>

          <div className="b-bottom-player__volume">
            {/* <i className='b-bottom-player__volume_icon' onClick={handleVolumeIconClick} /> */}
            <MuteIcon className={`${sound.muted ? 'b-bottom-player__volume_icon--muted' : ''} b-bottom-player__volume_icon`} onClick={handleVolumeIconClick} />
            <VolumeControl />
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BottomPlayer)
