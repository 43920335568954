import {
  NEW_PODCAST,
  SET_NEW_PODCAST_DATA,
  ADD_EPISODE,
  DELETE_EPISODE,
  UPDATE_EPISODE,
  REORDER_EPISODE,
  PODCAST_FORM_SAVE_FAIL,
  PODCAST_FORM_SAVE_SUCCESS,
  EDIT_PODCAST,
  PODCAST_FORM_RESET
} from '../constants/action-types'

import defaultCover from '../assets/preview-input.png'

const defaultState = {
  id: null,
  title: '',
  description: '',
  category_id: null,
  explicit: false,
  cover: {
    // fid, src
    url: defaultCover
  },
  publishAt: new Date(),
  status: true,
  tags: [],
  episodes: [],
  error: false,
  errorMessage: '',
  author: '',
  contact_name: '',
  contact_email: ''
}

export default (state = defaultState, { type, ...payload }) => {
  if (type === NEW_PODCAST) {
    return {
      ...defaultState
    }
  }

  if (type === EDIT_PODCAST) {
    return {
      ...defaultState,
      ...payload
    }
  }

  if (type === SET_NEW_PODCAST_DATA) {
    return {
      ...state,
      ...payload
    }
  }

  if (type === ADD_EPISODE) {
    return {
      ...state,
      episodes: [
        ...state.episodes,
        {
          ...payload
        }
      ]
    }
  }

  if (type === UPDATE_EPISODE) {
    return {
      ...state,
      episodes: state.episodes.map(episode => {
        if (episode.id === payload.id) {
          return payload
        }
        return episode
      })
    }
  }

  if (type === DELETE_EPISODE) {
    const episodes = state.episodes.filter(episode => episode.id !== payload.id)
    return {
      ...state,
      episodes
    }
  }

  if (type === REORDER_EPISODE) {
    const { from, to } = payload
    const episode = state.episodes[from]
    const episodes = Array.from(state.episodes)
    episodes.splice(from, 1)
    episodes.splice(to, 0, episode)

    return {
      ...state,
      episodes: episodes.map((episode, index) => ({
        ...episode,
        weight: index
      }))
    }
  }

  if (type === PODCAST_FORM_SAVE_FAIL) {
    return {
      ...state,
      ...payload
    }
  }

  if (type === PODCAST_FORM_SAVE_SUCCESS) {
    return {
      ...state,
      ...payload
    }
  }

  if (type === PODCAST_FORM_RESET) {
    return defaultState
  }

  return state
}
