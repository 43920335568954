import React from 'react'
import { connect } from 'react-redux'
import { updateMeta } from '../../actions/episode'
import { toPercent } from '../../helpers/common'

import './PlayerProgressbar.scss'

import BaseTrackpath, { CHANGE_MOUSE_UP, CHANGE_CLICK } from './BaseTrackpath'

const mapStateToProps = state => ({
  sound: state.sound
})
const mapDispatchToProps = dispatch => ({
  updateMeta: meta => dispatch(updateMeta(meta))
})

const PlayerProgressbar = ({ episodeId = null, sound, updateMeta, className = '' }) => {
  const isAvailable = () => episodeId !== null && episodeId === sound.episodeId

  const getCursorOffsetX = ({ event }) => {
    if (event.type.startsWith('touch')) {
      const { pageX = 0, target } = event.changedTouches[0]
      const { left = 0 } = target.getBoundingClientRect()

      return pageX - left
    }
    else {
      return event.nativeEvent.offsetX
    }
  }

  const handleChange = ({ percent, changeType }) => {
    if (changeType === CHANGE_MOUSE_UP || changeType === CHANGE_CLICK) {
      const currentTime = sound.meta.duration * (percent / 100)
      updateMeta({ changePosition: true, currentTime })
    }
  }

  const updateCursor = ({ percent, progressRef }) => {
    progressRef.current.style.width = `${percent}%`
  }

  return (
    <React.Fragment>
      {isAvailable() ? (
        <BaseTrackpath
          className={`${className} b-player__progressbar`}
          onChange={handleChange}
          updateCursor={updateCursor}
          getCursorOffsetX={getCursorOffsetX}
          value={toPercent(sound.meta.currentTime, sound.meta.duration)}
        />
      ) : (
        ''
      )}
    </React.Fragment>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerProgressbar)
