import React, { useEffect } from 'react'
import './App.scss'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Switch, Route, matchPath, Redirect } from 'react-router-dom'

import AuthForm from './auth/AuthForm'
import EpisodePage from './EpisodePage'
import PodcastOpenForm from './PodcastOpenForm'
import CategoryForm from './form/CategoryForm'
import Categories from './category/Categories'
import Player from './player/Player'
import PageHeader from './PageHeader'
import PodcastList from './PodcastList'
import Header from './Header'
import ProtectedRoute from './auth/ProtectedRoute'
import BottomPlayer from './BottomPlayer'
import UserPage from './UserPage'

import { getPodcasts } from '../thunks/podcast'
import { loadCategories } from '../thunks/categories'

import { CSSTransition, TransitionGroup } from 'react-transition-group'
import ErrorPage from './ErrorPage'
import { resetAppError } from '../actions/common'

const mapStateToProps = state => ({
  hasAppError: state.common.error.status
})

const mapDispatchToProps = dispatch => {
  return {
    loadCategories: () => dispatch(loadCategories()),
    getPodcasts: () => dispatch(getPodcasts()),
    resetAppError: () => dispatch(resetAppError())
  }
}

function App({ getPodcasts, hasAppError, loadCategories }) {
  useEffect(
    () => {
      getPodcasts()
      loadCategories()
    },
    [getPodcasts, loadCategories]
  )

  // prevent rerenders - podcast/:id === podcast/:id/.../
  const makeKey = ({ key, pathname }) => {
    const match = matchPath(pathname, {
      path: '/podcast/:id'
    })

    if (match && match.params.id) {
      return match.params.id
    }
    return key
  }

  return (
    <div className="b-ikfipod-app">
      <Router>
        <div className="container">
          <Header />
        </div>

        <PageHeader />

        {hasAppError ? <Redirect to="/error" /> : ''}

        <div className="container">
          {/* <ErrorPage /> */}
          <Route
            render={({ location }) => {
              // resetAppError()
              const key = makeKey(location)
              return (
                <TransitionGroup>
                  <CSSTransition key={key} classNames="window-transition" timeout={{ enter: 600, exit: 0 }}>
                    <Switch location={location}>
                      <Route path="/" exact component={PodcastList} />
                      <ProtectedRoute path="/podcast/create" exact component={PodcastOpenForm} />
                      <ProtectedRoute path="/podcast/edit/:id" exact component={PodcastOpenForm} />
                      <ProtectedRoute path="/category/create" exact component={CategoryForm} />
                      <ProtectedRoute path="/category/edit/:id" exact component={CategoryForm} />
                      <ProtectedRoute path="/category/list" exact component={Categories} />
                      <Route path="/podcast/:id" exact component={EpisodePage} />
                      <Route path="/podcast/:id/episode/:episodeId" exact component={EpisodePage} />
                      <ProtectedRoute path="/user" exact component={UserPage} />
                      <ProtectedRoute path="/batya/v/zdanii" exact component={AuthForm} mustBeLogged={false} />
                      <Route path="/error" exact component={ErrorPage} />
                      <Route
                        render={() => {
                          return <Redirect to={{ pathname: '/error', state: { error: 'Страница не найдена' } }} />
                        }}
                      />
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
              )
            }}
          />
        </div>

        <BottomPlayer />
        <Player />
      </Router>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
