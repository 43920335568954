import {
  EPISODE_FORM_UPLOAD_SUCCESS,
  EPISODE_FORM_UPLOAD_FAILED,
  EPISODE_FORM_UPDATE_NAME,
  EPISODE_FORM_UPDATE_DESCRIPTION,
  EPISODE_FORM_RESET_FORM,
  EPISODE_FORM_EDIT_EPISODE,
  EPISODE_FORM_UPDATE_FILENAME,
  PODCAST_FORM_RESET,
  SET_NEW_EPISODE_DATA
} from '../constants/action-types'

const defaultState = {
  title: '',
  description: '',
  file_id: null,
  isNew: true,
  src: null,
  weight: 0,
  error: false,
  errorMessage: '',
  filename: '',
  explicit: false
}

export default (state = defaultState, { type, ...payload }) => {
  if (type === EPISODE_FORM_UPLOAD_SUCCESS) {
    return {
      ...state,
      ...payload
    }
  }

  if (type === SET_NEW_EPISODE_DATA) {
    return {
      ...state,
      ...payload
    }
  }

  if (type === EPISODE_FORM_UPLOAD_FAILED) {
    return {
      ...state,
      ...payload,
      error: true
    }
  }

  if (type === EPISODE_FORM_UPDATE_NAME) {
    return {
      ...state,
      ...payload
    }
  }

  if (type === EPISODE_FORM_UPDATE_DESCRIPTION) {
    return {
      ...state,
      ...payload
    }
  }

  if (type === EPISODE_FORM_UPDATE_FILENAME) {
    return {
      ...state,
      filename: payload.filename
    }
  }

  if (type === EPISODE_FORM_RESET_FORM || type === PODCAST_FORM_RESET) {
    return {
      ...defaultState
    }
  }

  // todo: edit form
  if (type === EPISODE_FORM_EDIT_EPISODE) {
    return {
      ...payload
    }
  }

  return state
}
