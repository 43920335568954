import service from '../services/category'
import { setCategories, deleteCategory as deleteCategoryAction } from '../actions/categories'
import { setAppError } from '../actions/common'

export const loadCategories = () => async (dispatch, getState) => {
  const [data, error] = await service.all(getState().auth.jwt)
  if (!error) {
    dispatch(setCategories(data))
  } else {
    dispatch(setAppError(error))
  }
}

export const deleteCategory = id => async (dispatch, getState) => {
  const [data, error] = await service.delete(getState().auth.jwt, id)
  if (!error) {
    dispatch(deleteCategoryAction(data))
  } else {
    dispatch(setAppError(error))
  }
}
