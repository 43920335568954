import React from 'react'
import PlayerProgressbar from './PlayerProgressbar'
import PlayerProgressTime from './PlayerProgressTime'

const PlayerPlayback = ({ episodeId, duration }) => {
  return (
    <div className='b-player__playback'>
      <PlayerProgressbar className='b-player__playback_progress' episodeId={episodeId} />
      <div className='b-player__playback_time'>
        <PlayerProgressTime episodeId={episodeId} duration={duration} />
      </div>
    </div>
  )
}

export default PlayerPlayback
