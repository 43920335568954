import React, { useEffect, useState, useRef } from 'react'
import { toPercent } from '../../helpers/common'

export const CHANGE_UNKNOWN = 'CHANGE_UNKNOWN'
export const CHANGE_MOVE = 'CHANGE_MOVE'
export const CHANGE_CLICK = 'CHANGE_CLICK'
export const CHANGE_MOUSE_UP = 'CHANGE_MOUSE_UP'
export const CHANGE_MOUSE_DOWN = 'CHANGE_MOUSE_DOWN'
export const CHANGE_MOUSE_OUT = 'CHANGE_MOUSE_OUT'

const BaseTrackpath = ({ value = 100, className = '', onChange = () => {}, updateCursor = () => {}, getCursorOffsetX = () => {} }) => {
  const [mouseDown, setMouseDown] = useState(false)

  const ref = useRef(null)
  const progressRef = useRef(null)

  const updateCursorPosition = (percent, changeType = CHANGE_UNKNOWN) => {
    updateCursor({ref, progressRef, percent})
    onChange({ percent, changeType, ref, progressRef })
  }

  useEffect(() => {
    if (!mouseDown) {
      updateCursorPosition(value)
    }
  }, [mouseDown, value])

  const handleClick = event => {
    const percent = toPercent(getCursorOffsetX({event, ref, progressRef}), ref.current.offsetWidth)
    updateCursorPosition(percent, CHANGE_CLICK)
  }

  const handleMouseDown = event => {
    setMouseDown(true)
    updateCursorPosition(toPercent(getCursorOffsetX({event, ref, progressRef}), ref.current.offsetWidth))
  }

  const handleMouseMove = event => {
    if (mouseDown) {
      const percent = toPercent(getCursorOffsetX({event, ref, progressRef}), ref.current.offsetWidth)
      updateCursorPosition(percent, CHANGE_MOVE)
    }
  }

  const handleMouseUp = event => {
    // event.preventDefault()
    const percent = toPercent(getCursorOffsetX({event, ref, progressRef}), ref.current.offsetWidth)
    setMouseDown(false)
    onChange({percent, changeType: CHANGE_MOUSE_UP})
  }

  const handleMouseLeave = event => {
    if (mouseDown) {
      setMouseDown(false)
      updateCursorPosition(value, CHANGE_MOUSE_OUT)
    }
  }

  return (
    <React.Fragment>
      <div
        className={`${className}`}
        ref={ref}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onTouchStart={handleMouseDown}
        onTouchMove={handleMouseMove}
        onTouchEnd={handleMouseUp}
        onClick={handleClick}
        onMouseLeave={handleMouseLeave}
      >
        <div className={`${className}__bar`} ref={progressRef} />
      </div>
    </React.Fragment>
  )
}

export default BaseTrackpath
