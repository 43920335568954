import React from 'react'
import { connect } from 'react-redux'

import { CSSTransition, TransitionGroup } from 'react-transition-group'

const mapStateToProps = state => ({
  h1: state.common.h1
})

const PageHeader = ({ h1 }) => {
  return (
    <TransitionGroup>
      <CSSTransition key={h1} classNames='title-transition' timeout={{ enter: 800, exit: 0 }}>
        <div className='b-header-container'>
          {h1 && h1.length > 0 ? (
            <header className='b-header'>
              <h1 className='b-header__title'>{h1}</h1>
            </header>
          ) : (
            ''
          )}
        </div>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default connect(
  mapStateToProps,
  null
)(PageHeader)
