import { Service } from './index'

export default new class AuthService extends Service {
  async register(email, password) {
    const body = new FormData()
    body.append('email', email)
    body.append('password', password)

    const response = await fetch(this.url('auth/register'), {
      method: 'POST',
      body
    })

    const json = await response.json()

    if (json.error) {
      return [null, json.payload]
    }

    return [
      {
        jwt: json.payload.access_token,
        id: json.payload.user.id
      },
      false
    ]
  }

  async login(email, password) {
    const body = new FormData()
    body.append('email', email)
    body.append('password', password)

    const response = await fetch(this.url('auth/login'), {
      method: 'POST',
      body
    })

    const json = await response.json()

    if (json.error) {
      return [null, json.payload]
    }

    return [
      {
        jwt: json.payload.access_token,
        id: json.payload.user.id
      },
      false
    ]
  }

  async authenticate(jwt) {
    if (!jwt) {
      return [null, 'No token provided']
    }
    const { refreshToken } = jwt

    // try to refresh the token
    const body = new FormData()
    body.append('refreshToken', refreshToken)

    const response = await fetch(this.url('auth/login'), {
      method: 'post',
      body
    })

    const json = await response.json()

    if (json.error) {
      return [null, json.payload]
    }

    return [
      {
        jwt: json.payload
      },
      false
    ]
  }
}()
