import React from 'react'
import { connect } from 'react-redux'

import { setUserInfo } from '../../actions/user'

const mapStateToProps = state => ({
	info: state.user.info
})

const mapDispatchToProps = dispatch => ({
	setUserInfo: info => dispatch(setUserInfo(info))
})

const User = ({ info }) => {
	return (
		<div>
			<input type="text" placeholder="itunes:author" value={info.author} />
			<input type="text" placeholder="itunes:owner:name" value={info.owner.name} />
			<input type="text" placeholder="itunes:owner:email" value={info.owner.email} />
			<button>Save</button>
		</div>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(User)
