import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const mapStateToProps = state => ({
  loggedIn: state.auth.loggedIn
})

const ProtectedRoute = ({ mustBeLogged = true, loggedIn, component: Component, ...rest }) => {
  return <Route {...rest} render={props => (mustBeLogged === loggedIn ? <Component {...props} /> : <Redirect to={'/'} />)} />
}

export default connect(
  mapStateToProps,
  null
)(ProtectedRoute)
