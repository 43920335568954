import { combineReducers } from 'redux'
import podcasts from './podcasts'
import auth from './auth'
import podcastForm from './podcastForm'
import categories from './categories'
import categoryForm from './categoryForm'
import episodeForm from './episodeForm'
import sound from './sound'
import podcast from './podcast'
import user from './user'

import { SET_TITLE_H1, SET_APP_ERROR, RESET_APP_ERROR } from '../constants/action-types'

const initialState = {
  h1: '',
  error: {
    status: false,
    message: ''
  }
}

const common = (state = initialState, { type, ...payload }) => {
  if (type === SET_TITLE_H1) {
    return {
      ...state,
      ...payload
    }
  }

  if (type === SET_APP_ERROR) {
    return {
      ...state,
      error: {
        status: true,
        ...payload
      }
    }
  }

  if (type === RESET_APP_ERROR) {
    return {
      ...state,
      error: {
        ...initialState.error
      }
    }
  }

  return state
}

export default combineReducers({
  common,
  podcasts,
  auth,
  categories,
  sound,
  podcast,
  podcastForm,
  episodeForm,
  categoryForm,
  user
})
