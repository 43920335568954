import { SET_INITIAL, ADD_PODCAST, SET_PODCASTS, UPDATE_PODCAST, DELETE_PODCAST } from '../constants/action-types'

const defaultState = []

export default (state = defaultState, { type, ...payload }) => {
  if (type === SET_INITIAL) {
    return state
  }

  if (type === ADD_PODCAST) {
    return [{ ...payload }, ...state]
  }

  if (type === SET_PODCASTS) {
    return [...defaultState, ...payload.podcasts]
  }

  if (type === DELETE_PODCAST) {
    return [...state.filter(podcast => podcast.id !== payload.podcastId)]
  }

  if (type === UPDATE_PODCAST) {
    return [
      ...state.map(podcast => {
        if (podcast.id === payload.id) {
          return {
            ...payload
          }
        }
        return podcast
      })
    ]
  }

  return state
}
