import { SET_CATEGORIES, DELETE_CATEGORY, UPDATE_CATEGORY, ADD_CATEGORY } from '../constants/action-types'

const defaultState = []

export default (state = defaultState, { type, ...payload }) => {
  if (type === SET_CATEGORIES) {
    return [...payload.categories]
  }

  if (type === ADD_CATEGORY) {
    return [...state, payload]
  }

  if (type === DELETE_CATEGORY) {
    console.log(payload)
    return [...state.filter(category => category.id !== payload.id)]
  }

  if (type === UPDATE_CATEGORY) {
    return state.map(category => {
      if (category.id === payload.id) {
        return payload
      }
      return category
    })
  }

  return state
}
